* {
    margin: 0;
    padding: 0;
  }
  
  .policy-container {
    width: 70%;
    margin: auto;
  }
  p {
    margin-top: 1rem;
  }
  
  h4 {
    margin-top: 1rem;
  }
  
  